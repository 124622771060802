// colour codes imported and used all over app for styling
export const SKILLS_TYPEWRITER_LIST = [
    "Data Science",
    "Python",
    "Software Development",
    "LLMs",
    "Dev Ops",
    "Machine Learning",
    "Finance",
    "Typescript",
    "Data Engineering",
    "Web Development",
    "Government",
    "Cloud Infrastructure",
    "Jupyter Ecosystem",
    "Cyber Security",
    "Big Data",
    "Rust",
    "Artificial Intelligence",
    "Health",
    "Blockchain",
    "Data Engineering",
    "Insurance",
];

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
  }

  export const DEVICE_WIDTHS = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`
  };
  